import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight 
} from "@fortawesome/pro-regular-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import Partners from "../components/PartnersSponsors/Partners"
import Sponsors from "../components/PartnersSponsors/Sponsors"

const Registration = ({ data }) => (
  <Layout>
    <SEO title="Teton Ridge Classic 2021 Registration" />
    <PageTitle 
      title="Registration"
    />
    <Container fluid>
      <Row className="my-4">
        <Col>
        <h2>2024 Teton Ridge Classic Registration</h2>
        <div className="float-right centerOnSmall">
          <Img fluid={data.raceImage.childImageSharp.fluid} alt="Teton Ridge Classic" className="rounded" />
        </div>
            <p><strong>Registration is only available online.</strong></p>
            <p>Online registration closes the Friday before the race at 5 p.m.</p>
           
<hr />
            <div className="my-4">
            <a href="https://www.webscorer.com/register?raceid=330930" target="_blank" rel="noopener noreferrer"><Button className="trcButton">Click Here To Register {" "}<span><FontAwesomeIcon icon={faAngleRight}/></span></Button></a></div>
            <Row>
              <Col sm={6}>
              <p><strong>By December 15, 2023</strong><br />
            U20 and younger, any race: <strong>$15</strong><br />
            7 km, 15 km: <strong>$30</strong><br />
            Touring Class: <strong>$30</strong>
            </p>
              </Col>
              <Col sm={6}>
              <p><strong>After December 15, 2023</strong><br />
              U20 and younger, any race: <strong>$15</strong><br />
              4 km: <strong>$15</strong><br />
              8 km, 16 km: <strong>$30</strong><br />
              Touring Class: <strong>$30</strong>
              <br/>
              <strong>After December 15, 2023</strong>
              U20 and younger, any race: <strong>$20</strong><br />
              4 km: <strong>$20</strong><br />
              8 km, 16 km: <strong>$40</strong><br />
              Touring Class: <strong>$40</strong>
              </p>
              </Col>
              </Row>
        </Col>
      </Row>
    </Container>
    <Container fluid>
    <hr />
        <Row>
              <Partners />
            </Row>
            <hr />
            <Row>
              <Sponsors />
            </Row>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    raceImage: file(relativePath: { eq: "Ranch-002.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Registration
